var img3 = require("@/assets/mapimages/new/infobox-close.png");
var img4 = require("@/assets/mapimages/new/ic_location_blue.png");
var img5 = require("@/assets/mapimages/new/ic_info.png");

/// <summary>
/// 判断图层是否可见
/// </summary>
/// <param name="li">图层</param>
/// <param name="scale">比例</param>
/// <returns></returns>
var subLayerIsVisible = function(li, scale) {
    var result = false;
    if (li.maxScale > 0 && li.minScale > 0) {
        result =
            li.maxScale <= Math.ceil(scale) && Math.floor(scale) <= li.minScale;
    } else if (li.maxScale > 0) {
        result = li.maxScale <= Math.ceil(scale);
    } else if (li.minScale > 0) {
        result = Math.floor(scale) <= li.minScale;
    } else {
        result = true;
    }
    return result;
};

/**
 * 创建高德地图对象
 */
var createTintLayer = function(layer, Color, request) {
    //高德地图对象
    return layer.createSubclass({
        properties: {
            urlTemplate: null,
            tint: {
                value: null,
                type: Color,
            },
        },

        // generate the tile url for a given level, row and column
        getTileUrl: function(level, row, col) {
            return this.urlTemplate
                .replace("{z}", level)
                .replace("{x}", col)
                .replace("{y}", row);
        },

        fetchTile: function(level, row, col) {
            var url = this.getTileUrl(level, row, col);

            return request(url, {
                responseType: "image",
                allowImageDataAccess: true,
            }).then(
                function(response) {
                    var image = response.data;
                    var width = this.tileInfo.size[0];
                    var height = this.tileInfo.size[0];

                    var canvas = document.createElement("canvas");
                    var context = canvas.getContext("2d");
                    canvas.width = width;
                    canvas.height = height;

                    context.drawImage(image, 0, 0, width, height);

                    return canvas;
                }.bind(this)
            );
        },
    });
};

var createTitle = function(type, graphic, layerId) {
    var title = "";
    if (type == "公路") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">${graphic.attributes["LXMC"]}</div>
                             <i  onclick="closePop()" >
                              <img src="${img3}" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>路线编码 : </span>
                             <span>${graphic.attributes["LXBM"]} </span>
                             </div>
                             <div class="hang">
                             <span>技术等级 : </span>
                             <span>${graphic.attributes["Ldjsdj"]} </span>
                             </div>
                             <div class="hang">
                             <span>路面类型 : </span>
                             <span>${graphic.attributes["Ldlmlx"]} </span>
                             </div>
                             <div class="hang">
                             <span>起点桩号 :</span>
                             <span>${graphic.attributes["Qdzh"]} </span>
                             </div>
                             <div class="hang">
                             <span>止点桩号 : </span>
                             <span>${graphic.attributes["Zdzh"]} </span>
                             </div>
                             <div class="hang">
                             <span>路段里程 : </span>
                             <span>${Math.abs(
                               graphic.attributes["Zdzh"] -
                                 graphic.attributes["Qdzh"]
                             ).toFixed(3)}公里 </span>
                             </div>
                         </div>
                         <div class="fotter">
                               <span onclick="showForm('${
                                 graphic.attributes["Ld_guid"]
                               }','gl')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                            <span onclick="queryLx('${
                              graphic.attributes["LXBM"]
                            }','${layerId}' )">
                              <i>
                               <img src="${img4}" alt="">
                              </i>
                              整线定位
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "匝道") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">${graphic.attributes["Zdmc"]}</div>
                             <i  onclick="closePop()" >
                              <img src="${img3}" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>匝道编号 : </span>
                             <span>${graphic.attributes["Zdbh"]} </span>
                             </div>
                             <div class="hang">
                             <span>匝道长度 : </span>
                             <span>${graphic.attributes["Zdcd"]} </span>
                             </div>
                             <div class="hang">
                             <span>匝道宽度 : </span>
                             <span>${graphic.attributes["Zdkd"]} </span>
                             </div>
                             <div class="hang">
                             <span>建成时间 :</span>
                             <span>${graphic.attributes["Jcsj"]} </span>
                             </div>
                         </div>
                         <div class="fotter">
                               <span onclick="showForm('${graphic.attributes["Zd_guid"]}','zd')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "桥梁") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">桥梁</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>桥梁名称 : </span>
                             <span>${graphic.attributes.Qlmc} </span>
                             </div>
                             <div class="hang">
                             <span>桥梁编号 : </span>
                             <span>${graphic.attributes.Qlbh} </span>
                             </div>
                             <div class="hang">
                             <span>中心桩号 : </span>
                             <span>${graphic.attributes.Centerzh} </span>
                             </div>
                         </div>
                         <div class="fotter">
                             <span onclick="showForm('${graphic.attributes["Glql_guid"]}','ql')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }

    if (type == "涵洞") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">涵洞</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>涵洞编号 : </span>
                             <span> ${graphic.attributes.Hdbh}</span>
                             </div>
                             <div class="hang">
                             <span>涵洞跨径 : </span>
                             <span>${graphic.attributes.Hdkj} </span>
                             </div>
                             <div class="hang">
                             <span>中心桩号 : </span>
                             <span>${graphic.attributes.Zxzh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                              <span onclick="showForm('${graphic.attributes["Hd_guid"]}','hd')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }

    if (type == "隧道") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">隧道</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>隧道名称 : </span>
                             <span>${graphic.attributes.Sdmc}</span>
                             </div>
                             <div class="hang">
                             <span>入口桩号 : </span>
                             <span>${graphic.attributes.Rkzh} </span>
                             </div>
                             <div class="hang">
                             <span>隧道长度 : </span>
                             <span>${graphic.attributes.Sdcd}</span>
                             </div>
                             <div class="hang">
                             <span>建成时间 : </span>
                             <span>${graphic.attributes.Jcsj}</span>
                             </div>
                         </div>
                         <div class="fotter">
                               <span onclick="showForm('${graphic.attributes["Glsd_guid"]}','sd')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }

    if (type == "渡口") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">渡口</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>渡口编号 : </span>
                             <span> ${graphic.attributes.Dkbh}</span>
                             </div>
                             <div class="hang">
                             <span>渡口名称 : </span>
                             <span> ${graphic.attributes.Dkmc}</span>
                             </div>
                             <div class="hang">
                             <span>渡口宽度 : </span>
                             <span>${graphic.attributes.Dkkd}</span>
                             </div>
                             <div class="hang">
                             <span>起点桩号 : </span>
                             <span>${graphic.attributes.Qdzh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Dk_guid"]}','dk')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }

    if (type == "乡镇") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">乡镇</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>乡镇编码 : </span>
                             <span>${graphic.attributes.Xzbm}</span>
                             </div>
                             <div class="hang">
                             <span>乡镇名称 : </span>
                             <span>${graphic.attributes.Xzmc}</span>
                             </div>
                             <div class="hang">
                             <span>乡镇人口 : </span>
                             <span>${graphic.attributes.Xzrk}人</span>
                             </div>
                             <div class="hang">
                             <span>建制村数 : </span>
                             <span>${graphic.attributes.Sxjzcsl}个</span>
                             </div>
                         </div>
                         <div class="fotter">
                            <span onclick="showForm('${graphic.attributes["Xz_guid"]}','xz')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "建制村") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">建制村</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>建制村编码 : </span>
                             <span>${graphic.attributes.Jzcbm}</span>
                             </div>
                             <div class="hang">
                             <span>建制村名称 : </span>
                             <span>${graphic.attributes.Jzcmc}</span>
                             </div>
                             <div class="hang">
                             <span>建制村人口 : </span>
                             <span>${graphic.attributes.Jzcrk}人</span>
                             </div>
                             <div class="hang">
                             <span>自然村数量 : </span>
                             <span>${graphic.attributes.Sxzrcsl}个</span>
                             </div>
                         </div>
                         <div class="fotter">
                            <span onclick="showForm('${graphic.attributes["Jzc_guid"]}','jzc')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "点" || type == "线") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">采集${type}</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                            <div class="hang">
                             <span>采集名称 : </span>
                             <span> ${graphic.attributes.CollectName}</span>
                             </div>
                             <div class="hang">
                             <span>采集用户 : </span>
                             <span> ${graphic.attributes.CollectUser}</span>
                             </div>
                             <div class="hang">
                             <span>审核状态 : </span>
                             <span>${graphic.attributes.UploadStatus}</span>
                             </div>
                             <div class="hang">
                             <span>空间类型 : </span>
                             <span>${type}</span>
                             </div>                               
                         </div>
                         <div class="fotter">
                            <span onclick="showColForm()">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "测量") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">测量桩号</div>
                             <i  onclick="closePop()" >
                              <img src="${img3}" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>路线编码 : </span>
                             <span>${graphic["LXBM"]} </span>
                             </div>
                             <div class="hang">
                             <span>路线名称 : </span>
                             <span>${graphic["LXMC"]} </span>
                             </div>
                             <div class="hang">
                             <span>技术等级 : </span>
                             <span>${graphic["Ldjsdj"]} </span>
                             </div>
                             <div class="hang">
                             <span>路面类型 : </span>
                             <span>${graphic["Ldlmlx"]} </span>
                             </div>
                             <div class="hang">
                             <span>路线方向 :</span>
                             <span>${
                               graphic.Lxfxdm == 1 ? "上行" : "下行"
                             } </span>
                             </div>
                             <div class="hang">
                             <span>桩号位置 : </span>
                             <span>${graphic.MValue.toFixed(3)} </span>
                             </div>
                         </div>                          
                        </div>`;
    }
    if (type == "交叉道口") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">交叉道口</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>道口编号 : </span>
                             <span> ${graphic.attributes.Jcdkbh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Jdk_guid"]}','pjdk')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "标志标牌") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">标志标牌</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>设施编号 : </span>
                             <span> ${graphic.attributes.Ssbh}</span>
                             </div>
                             <div class="hang">
                             <span>设施桩号 : </span>
                             <span> ${graphic.attributes.Sszh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Bzbp_guid"]}','bzbp')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "收费站") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">收费站</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>收费站编号 : </span>
                             <span> ${graphic.attributes.Sfzbh}</span>
                             </div>
                             <div class="hang">
                             <span>收费站名称 : </span>
                             <span> ${graphic.attributes.Sfzmc}</span>
                             </div>
                             <div class="hang">
                             <span>负责人 : </span>
                             <span> ${graphic.attributes.Fzr}</span>
                             </div>
                             <div class="hang">
                             <span>联系电话 : </span>
                             <span>${graphic.attributes.Lxdh}</span>
                             </div>
                             <div class="hang">
                             <span>建成时间 : </span>
                             <span>${graphic.attributes.Jcsj}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Sfz_guid"]}','sfz')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "加油站") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">加油站</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>加油站编号 : </span>
                             <span> ${graphic.attributes.Jyzbh}</span>
                             </div>
                             <div class="hang">
                             <span>加油站名称 : </span>
                             <span> ${graphic.attributes.Jyzmc}</span>
                             </div>
                             <div class="hang">
                             <span>负责人 : </span>
                             <span> ${graphic.attributes.Fzrxm}</span>
                             </div>
                             <div class="hang">
                             <span>联系电话 : </span>
                             <span>${graphic.attributes.Lxdh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Jyz_guid"]}','jyz')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "交调观测站") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">交调观测站</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>交调站名称 : </span>
                             <span> ${graphic.attributes.Dczmc}</span>
                             </div>
                             <div class="hang">
                             <span>起点桩号 : </span>
                             <span> ${graphic.attributes.Qdzh}</span>
                             </div>
                             <div class="hang">
                             <span>止点桩号 : </span>
                             <span> ${graphic.attributes.Zdzh}</span>
                             </div>
                             <div class="hang">
                             <span>中心桩号 : </span>
                             <span>${graphic.attributes.Zxzh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Gcz_guid"]}','gcz')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "养护中心") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">养护中心</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>养护工区名称 : </span>
                             <span> ${graphic.attributes.Ssmc}</span>
                             </div>
                             <div class="hang">
                             <span>房屋设施产权所属 : </span>
                             <span> ${graphic.attributes.Fwsscqss}</span>
                             </div>
                             <div class="hang">
                             <span>负责人 : </span>
                             <span>${graphic.attributes.Fzy}</span>
                             </div>
                             <div class="hang">
                             <span>联系电话 : </span>
                             <span>${graphic.attributes.Lxdh}</span>
                             </div>
                             <div class="hang">
                             <span>管养路段 : </span>
                             <span>${graphic.attributes.Gyld}</span>
                             </div>
                               <div class="hang">
                             <span>建成时间 : </span>
                             <span>${graphic.attributes.Jcsj}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Yhzx_guid"]}','yhgq')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "服务区") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">服务区</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>服务区编号 : </span>
                             <span> ${graphic.attributes.Fwqbh}</span>
                             </div>
                             <div class="hang">
                             <span>服务区名称 : </span>
                             <span> ${graphic.attributes.Fwqmc}</span>
                             </div>
                             <div class="hang">
                             <span>房屋设施产权 : </span>
                             <span> ${graphic.attributes.Fwsscq}</span>
                             </div>
                             <div class="hang">
                             <span>负责人 : </span>
                             <span>${graphic.attributes.Fzr}</span>
                             </div>
                             <div class="hang">
                             <span>联系电话 : </span>
                             <span>${graphic.attributes.Lxdh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Fwq_guid"]}','fwq')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "超限监测站") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">超限监测站</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>监测站名称 : </span>
                             <span> ${graphic.attributes.Cxysjczmc}</span>
                             </div>
                             <div class="hang">
                             <span>负责人 : </span>
                             <span> ${graphic.attributes.Fzr}</span>
                             </div>
                             <div class="hang">
                             <span>联系电话 : </span>
                             <span> ${graphic.attributes.Lxdh}</span>
                             </div>
                             <div class="hang">
                             <span>建成时间 : </span>
                             <span>${graphic.attributes.Jcsj}</span>
                             </div>
                             <div class="hang">
                             <span>中心桩号 : </span>
                             <span>${graphic.attributes.Zxzh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Cxjcz_guid"]}','cxjcz')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "门架") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">门架</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>门架编号 : </span>
                             <span> ${graphic.attributes.Sfmjbh}</span>
                             </div>
                             <div class="hang">
                             <span>门架名称 : </span>
                             <span> ${graphic.attributes.Mjmc}</span>
                             </div>
                             <div class="hang">
                             <span>门架桩号 : </span>
                             <span> ${graphic.attributes.Zh}</span>
                             </div>
                         </div>
                         <div class="fotter">
                           <span onclick="showForm('${graphic.attributes["Mj_guid"]}','lmj')">
                              <i>
                                <img src="${img5}" alt="">
                              </i>
                              查看详情
                            </span>
                        </div>
                        </div>`;
    }
    if (type == "采集点位") {
        title = `<div style='height:100%; width:100%;' class='popup_select' >
                        <div class="header">
                         <div class="hang">${graphic.attributes.ZRCMC}</div>
                             <i>
                              <img src="${img3}" onclick="closePop()" alt="">
                              </i>
                         </div>
                         <div class="center">
                             <div class="hang">
                             <span>所属单位 : </span>
                             <span> ${graphic.attributes.Dqbhmc}</span>
                             </div>
                             <div class="hang">
                             <span>所属乡镇 : </span>
                             <span> ${graphic.attributes.TownShipName}</span>
                             </div>
                             <div class="hang">
                             <span>所属建制村 : </span>
                             <span> ${graphic.attributes.VillageName}</span>
                             </div>
                             <div class="hang">
                             <span>户籍数(户) : </span>
                             <span> ${graphic.attributes.HS}</span>
                             </div>
                             <div class="hang">
                             <span>户籍人口数(人) : </span>
                             <span> ${graphic.attributes.RKS}</span>
                             </div>
                         </div>
                        </div>`;
    }
    return title;
};

var sortNumbers = function(a, b) {
    return a - b;
};

//计算路段中心点图片的角度
var calculateAngle = function(startP, endP) {
    var yminmus = endP[1] - startP[1];
    var xminus = endP[0] - startP[0];
    var radians = yminmus / xminus;
    var angle = 0;

    radians = Math.atan(radians);
    if (radians < 0) {
        if (yminmus < 0 && xminus > 0) {
            angle = radians * (180 / Math.PI) * -1;
        }
        if (yminmus > 0 && xminus < 0) {
            angle = radians * (180 / Math.PI) * -1 + 180;
        }
    }

    if (radians > 0) {
        if (yminmus < 0 && xminus < 0) {
            angle = radians * (180 / Math.PI) * -1 + 180;
        }
        if (yminmus > 0 && xminus > 0) {
            angle = radians * (180 / Math.PI) * -1;
        }
    }
    return angle;
};

/**
 * 根据提供的属性去重
 * @param {数组} arr 
 * @param {去重字段} prop 
 * @returns 
 */
var arrDistinctByProp = function(arr, prop) {
    let obj = {};
    return arr.reduce(function(preValue, item) {
        obj[item[prop]] ? '' : obj[item[prop]] = true && preValue.push(item);
        return preValue
    }, []);
};

/**
 * 对象数组排序
 * @param {*} obj1 
 * @param {*} obj2 
 * @returns 
 */
var compare = function(obj1, obj2) {
    var val1 = obj1.index;
    var val2 = obj2.index;
    if (val1 < val2) {
        return -1;
    } else if (val1 > val2) {
        return 1;
    } else {
        return 0;
    }
}

export {
    subLayerIsVisible,
    createTintLayer,
    createTitle,
    sortNumbers,
    calculateAngle,
    arrDistinctByProp,
    compare
}