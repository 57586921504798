var apiDomainCss = "http://gisapi.hljzztech.com/arcgis_js_api/javascript/4.22/esri/themes/light/main.css";
var apiDomainJs = "http://gisapi.hljzztech.com/arcgis_js_api/javascript/4.22/init.js";

var RestUrl = "http://39.100.134.93:6080"; //rest服务地址

var LdUrl = RestUrl + "/arcgis/rest/services/Gis2013_PROJ_LD/MapServer/"; //路段
var trafficUrl = RestUrl + "/arcgis/rest/services/Gis2013_PROJ_Traffic/MapServer/"; //路况
var AmapUrl = RestUrl + "/arcgis/rest/services/Gis2013_PROJ_AMAP/MapServer/"; //高德
var QueryUrl = RestUrl + "/arcgis/rest/services/Gis2013_PROJ_LD_QUERY/MapServer/"; //查询
var BgUrl = RestUrl + "/arcgis/rest/services/BeiJingProj/MapServer"; //背景
var DiMaoUrl = "http://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer"; //地貌
var YingXiangUrl = "http://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/"; //影像

var amapImageUrl = "http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}";
var amapStreetUrl = "http://webst01.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}";
var tdtUrl = "https://{subDomain}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={col}&TILEROW={row}&TILEMATRIX={level}&tk=4a302511a2a820345b2eced86dc18ec4";

//桩号定位url
var retrieveRoutePointGeometryUrl = RestUrl + "/arcgis/rest/services/Gis2013_PROJ_ROUTE/MapServer/exts/RestSOETest/RetrieveRoutePointGeometry";
//测量桩号url
var IdentifyByLXBMUrl = RestUrl + "/arcgis/rest/services/Gis2013_PROJ_ROUTE/MapServer/exts/RestSOETest/IdentifyByLXBM";

var planUrl = RestUrl + "/arcgis/rest/services/plan/MapServer";
var ntzyd = 'http://39.100.134.93:6080/arcgis/rest/services/nongtian/MapServer';
export {
    ntzyd,
    apiDomainCss,
    apiDomainJs,
    RestUrl,
    LdUrl,
    trafficUrl,
    AmapUrl,
    QueryUrl,
    BgUrl,
    DiMaoUrl,
    YingXiangUrl,
    amapImageUrl,
    amapStreetUrl,
    tdtUrl,
    retrieveRoutePointGeometryUrl,
    IdentifyByLXBMUrl,
    planUrl
};
